<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">{{
        $t("menu.access")
      }}</va-card-title>
    </va-card>

    <va-card class="mb-4">
      <va-card-actions align="right">
        <va-button icon="fa-icon fa fa-plus" @click="showAddAcc = true">{{
          $t("acc_view.add_acc")
        }}</va-button>
      </va-card-actions>

      <va-card-content>
        <div class="row">
          <div class="flex xs12 sm3 md4 lg3 xl2">
            <va-select
              v-model.number="searchBySelected"
              :label="$t('acc_view.search_by')"
              text-by="label"
              value-by="id"
              track-by="id"
              :options="searchBy"
            />
          </div>

          <div class="flex xs12 sm7 md6 lg5 xl4">
            <va-input
              v-model="term"
              :placeholder="
                $t('acc_view.search_by') +
                ' ' +
                searchBy[searchBySelected].label
              "
              clearable
            >
              <template #prependInner>
                <va-icon name="fa fa-search" />
              </template>
            </va-input>
          </div>

          <div class="flex xs12 sm2 md2 lg2 xl2 offset--lg2 offset--xl4">
            <va-select
              v-model.number="perPage"
              :label="$t('tables_common.per_page')"
              :options="perPageOptions"
            />
          </div>
        </div>

        <va-data-table
          :items="filteredData"
          :columns="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :no-data-html="$t('tables_common.no_data')"
          :hoverable="true"
          :loading="loading"
          :striped="true"
        >
          <template #header(id)></template>

          <template #cell(id)="{ source: id }">
            <va-popover
              :message="`${$t('tables_common.edit')}`"
              placement="top"
            >
              <va-button
                flat
                color="info"
                icon="fa fa-pencil"
                @click="editAcc(id)"
              />
            </va-popover>

            <va-popover
              :message="`${$t('tables_common.delete')}`"
              placement="top"
            >
              <va-button
                flat
                color="danger"
                icon="fa fa-trash"
                @click="removeAcc(id)"
              />
            </va-popover>
          </template>
        </va-data-table>

        <div class="flex-center mt-3">
          <va-pagination
            v-if="pages > 1"
            v-model="currentPage"
            :pages="pages"
          />
        </div>
      </va-card-content>
    </va-card>

    <va-modal v-model="showAddAcc" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("acc_view.add_acc") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.name"
              :label="$t('acc_view.acc_name')"
              :error="!!errors.addAccErrors.nameErrors.length"
              :error-messages="errors.addAccErrors.nameErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.issuer"
              :label="$t('acc_view.acc_issuer')"
              :error="!!errors.addAccErrors.issuerErrors.length"
              :error-messages="errors.addAccErrors.issuerErrors"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelAdd" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="addAccess" icon="fa-icon fa fa-plus">{{
          $t("common_forms.create")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal v-model="showEditAcc" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("acc_view.edit_acc") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="editedAcc.name"
              :label="$t('acc_view.acc_name')"
              :error="!!errors.editAccErrors.nameErrors.length"
              :error-messages="errors.editAccErrors.nameErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="editedAcc.issuer"
              :label="$t('acc_view.acc_issuer')"
              :error="!!errors.editAccErrors.issuerErrors.length"
              :error-messages="errors.editAccErrors.issuerErrors"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelEdit" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="editAccess" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showConfirmDelete"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('acc_view.delete_acc_confirm') + ' \'' + editedAcc.name + '\'?'
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("acc_view.delete_acc") }}
        </p>
      </template>
      <template #footer>
        <va-button color="info" @click="cancelDelete" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="danger" @click="confirmDelete" icon="fa fa-trash">{{
          $t("common_forms.delete")
        }}</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  data() {
    return {
      term: null,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      currentPage: 1,
      loading: true,
      tableData: [],
      showAddAcc: false,
      showConfirmDelete: false,
      showEditAcc: false,
      addForm: {
        name: "",
        issuer: "",
      },
      errors: {
        addAccErrors: {
          nameErrors: [],
          issuerErrors: [],
        },
        editAccErrors: {
          nameErrors: [],
          issuerErrors: [],
        },
      },
      editedAcc: {
        name: "",
        issuer: "",
        id: "",
      },
      searchBySelected: 0,
    };
  },

  created() {
    this.getAccs();
    this.loading = false;
  },

  computed: {
    fields() {
      return [
        {
          key: "name",
          headerTitle: this.$t("acc_view.acc_name"),
          label: this.$t("acc_view.acc_name"),
          sortField: "name",
          sortable: true,
        },
        {
          key: "issuer",
          headerTitle: this.$t("acc_view.acc_issuer"),
          label: this.$t("acc_view.acc_issuer"),
          sortField: "issuer",
          sortable: true,
        },
        {
          key: "id",
          headerTitle: "",
          label: "",
          align: "right",
        },
      ];
    },

    searchBy() {
      return [
        {
          id: 0,
          label: this.$t("acc_view.acc_name"),
          search: "name",
        },
        {
          id: 1,
          label: this.$t("acc_view.acc_issuer"),
          search: "issuer",
        },
      ];
    },

    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.tableData;
      }

      if (this.searchBy[this.searchBySelected]) {
        switch (this.searchBy[this.searchBySelected].search) {
          case "name":
            return this.tableData.filter((item) => {
              return item.name
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          case "issuer":
            return this.tableData.filter((item) => {
              return item.issuer
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          default:
            return this.tableData;
        }
      } else {
        return this.tableData;
      }
    },

    pages() {
      const pages =
        this.perPage && this.perPage !== 0
          ? Math.ceil(this.filteredData.length / this.perPage)
          : this.filteredData.length;
      if (pages < this.currentPage) {
        this.currentPage = pages === 0 ? 1 : pages;
      }
      return pages;
    },

    addFormReady() {
      return (
        !this.errors.addAccErrors.nameErrors.length &&
        !this.errors.addAccErrors.issuerErrors.length
      );
    },
    editFormReady() {
      return (
        !this.errors.editAccErrors.nameErrors.length &&
        !this.errors.editAccErrors.issuerErrors.length
      );
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term;
    }, 400),

    addAccess() {
      // console.log('Form submitted' + this.addForm.name)
      this.errors.addAccErrors.nameErrors = this.addForm.name
        ? []
        : [this.$t("acc_view.acc_name_not_present")];
      this.errors.addAccErrors.issuerErrors = this.addForm.issuer
        ? []
        : [this.$t("acc_view.acc_issuer_not_present")];
      if (!this.addFormReady) {
        // console.log('form not valid')
        return;
      }
      this.backend.addAcc(this.addForm).then((d2) => {
        if (d2 !== false) {
          this.addForm.name = "";
          this.addForm.issuer = "";
          this.getAccs();
          this.showAddAcc = false;
        } else {
          this.launchToast(this.$t("acc_view.error_create"), "warning");
        }
      });
    },

    cancelAdd() {
      // console.log('Clearing data on cancel dialog')
      this.addForm.name = "";
      this.addForm.issuer = "";
      this.errors.addAccErrors.nameErrors = [];
      this.errors.addAccErrors.issuerErrors = [];
      this.showAddAcc = false;
    },

    getAccs() {
      this.backend.listAcc().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.tableData = data;
        } else {
          this.tableData = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },

    removeAcc(accId) {
      // console.log('Called remove')
      const acc = this.tableData.find(({ id }) => id === accId);
      this.editedAcc.id = acc.id;
      this.editedAcc.name = acc.name;
      this.editedAcc.issuer = acc.issuer;
      this.showConfirmDelete = true;
    },

    cancelDelete() {
      this.editedAcc.id = "";
      this.editedAcc.name = "";
      this.editedAcc.issuer = "";
      this.showConfirmDelete = false;
    },

    confirmDelete() {
      this.backend.removeAcc(this.editedAcc.id).then((d2) => {
        if (d2 !== false) {
          this.editedAcc.id = "";
          this.editedAcc.name = "";
          this.editedAcc.issuer = "";
          this.getAccs();
          this.showConfirmDelete = false;
        } else {
          this.launchToast(this.$t("acc_view.error_delete"), "danger");
        }
      });
    },

    editAcc(accId) {
      // console.log('Called edit')
      const acc = this.tableData.find(({ id }) => id === accId);
      this.editedAcc.id = acc.id;
      this.editedAcc.name = acc.name;
      this.editedAcc.issuer = acc.issuer;
      this.showEditAcc = true;
    },

    cancelEdit() {
      this.editedAcc.id = "";
      this.editedAcc.name = "";
      this.editedAcc.issuer = "";
      this.showEditAcc = false;
    },

    editAccess() {
      this.errors.editAccErrors.nameErrors = this.editedAcc.name
        ? []
        : [this.$t("acc_view.acc_name_not_present")];
      this.errors.editAccErrors.issuerErrors = this.editedAcc.issuer
        ? []
        : [this.$t("acc_view.acc_issuer_not_present")];
      if (!this.editFormReady) {
        // console.log('form not valid')
        return;
      }
      this.backend.editAcc(this.editedAcc.id, this.editedAcc).then((d2) => {
        if (d2 !== false) {
          this.editedAcc.id = "";
          this.editedAcc.name = "";
          this.editedAcc.issuer = "";
          this.getAccs();
          this.showEditAcc = false;
        } else {
          this.launchToast(this.$t("acc_view.error_edit"), "warning");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
